import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, cloneDeep } from "lodash";

import { sendMessage, setConversation } from "../../actions/message";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import UserInfo from "../../services/user-info";

import Rate from '../rate/rate';
import ButtonPrint from '../buttonPrint/buttonPrint';

import "./chat-actions.scss";

class ChatAction extends Component {

  state = {
    singleHide: true,
  }

  checkListOptions = []

  messageChecklist

  static getDerivedStateFromProps(newProps, prevState) {
    return { singleHide: newProps.actionData.singleHide };
  }

  actions = {
    list: () => this.listRender(),
    buttons: () => this.buttonsRender(),
    quick_replies: () => this.quickReplieRender(),
    rate: () => this.rateRender(),
    checklist: () => this.checklistRender()
  }

  types = {
    map: (value, index) => this.mapHandler(value, index),
    web_url: (value, index) => this.webUrlHandler(value, index),
    postback: (value, index) => this.postbackHandler(value, index),
  }

  constructor(props) {
    super(props);

    this.checkHide = this.checkHide.bind(this);
    this.getAction = this.getAction.bind(this);
    this.mapHandler = this.mapHandler.bind(this);
    this.rateRender = this.rateRender.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.webUrlHandler = this.webUrlHandler.bind(this);
    this.buttonsRender = this.buttonsRender.bind(this);
    this.postbackHandler = this.postbackHandler.bind(this);
    this.checklistRender = this.checklistRender.bind(this);
    this.enviarCheckListParaWatson = this.enviarCheckListParaWatson.bind(this)
  }

  mapHandler(value, index) {
    if (typeof value !== "object") return false;

    const target = value.self ? "_self" : "_blank";
    return (
      <a className="map-button" key={index} target={target} href={value.url}>
        {value.title}
      </a>
    )
  }

  postbackHandler(value, index) {
    let onClickClass = ""

    if (typeof value !== "object") return false;
    if (this.props.messages.length !== this.props.position + 1 && this.props.actionType !== "list") {
      return false;
    }

    const buttonClass = `postback-button ${this.props.actionType} ${onClickClass}`;

    return (
      <button key={index} className={buttonClass} onClick={e => this.sendMessage(e.currentTarget.parentNode, value.payload, index)}>
        {value.title}
      </button>
    )
  }

  webUrlHandler(value, index) {
    if (typeof value !== "object") return false;

    const target = value.self ? "_self" : "_blank";

    return (
      <a className="link buttons" key={index} target={target} href={value.url}>
        {value.title}
      </a>
    )
  }

  storeHide(buttonIndex) {
    const { position, actionType } = this.props;
    const newState = cloneDeep(this.props.messages)

    if (actionType !== 'list') {
      newState[position].actions[actionType][buttonIndex].hide = true;
    }

    if (actionType === 'list') {
      const list = newState[position].actions[actionType].map(action => {
        action.hide = true
        return action
      })

      newState[position].actions[actionType] = list
    }
    this.props.setConversation(newState);
  }

  handleClickViaCapturing(e) {
    let index = this.checkListOptions.indexOf(e.target.value)

    if (index > -1) {
      this.checkListOptions.splice(index, 1)
    } else {
      this.checkListOptions.push(e.target.value)
    }
  }

  enviarCheckListParaWatson() {
    this.messageChecklist = this.checkListOptions.toString()
    this.props.ws.emit('customerSendMessageToBot', this.sendWebSocketObject(this.messageChecklist));
  }

  sendMessage(node, message, buttonIndex) {
    if (this.props.actionType !== "list") {
      node.classList.add("omni-hide");
      this.storeHide(buttonIndex);
    }

    if (this.props.actionType === "list") {
      node.parentNode.classList.add("omni-hide");
      this.storeHide(buttonIndex);
    }

    this.props.ws.emit('customerSendMessageToBot', this.sendWebSocketObject(message));
    // this.props.sendMessage({ message });
  }

  sendWebSocketObject = (message) => (
    JSON.stringify({
      text: message,
      context: !isEmpty(this.props.conversation) ? this.props.conversation : {},
      infosUser: UserInfo.getInfo(),
    })
  );

  quickReplieRender() {
    if (!(this.props.actionData instanceof Array)) return false;
    // @martini 2020-12-28 keep buttons quickReplies after timeout warn/terminate.
    // if (this.props.messages.length !== this.props.position + 1) {
    //   return false;
    // }
    if (this.props.actionData.length >= 3) {
      return (
        <div className="quick-reply-container-3">
          {
            this.props.actionData.map((value, index) => {
              return (
                <button title={value.title} key={index} className="quick-replies eds-style-text" onClick={e => this.sendMessage(e.currentTarget.parentNode, value.payload, index)}>
                  {value.title}
                </button>
              );
            })
          }
        </div>
      )
    } else {
      return (
        <div className="quick-reply-container">
          {
            this.props.actionData.map((value, index) => {
              return (
                <button title={value.title} key={index} className="quick-replies" onClick={e => this.sendMessage(e.currentTarget.parentNode, value.payload, index)}>
                  {value.title}
                </button>
              );
            })
          }
        </div>
      )
    }
  }

  checklistRender() {
    if (!(this.props.actionData instanceof Array)) return false;
    if (this.props.messages.length !== this.props.position + 1) {
      return false;
    }
    return (
      <>
        <div className='ckeckbox-container'>
          {
            this.props.actionData.map((value, index) => {
              return (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="value.title"
                        key={index}
                        onChange={(e) => this.handleClickViaCapturing(e)}
                        value={value.title}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={value.title}
                  />
                  <br /><br />
                </>
              );
            })
          }
        </div>
        <button className="quick-replies" onClick={this.enviarCheckListParaWatson}>Continuar</button>
      </>
    )
  }

  buttonsRender() {
    if (!(this.props.actionData instanceof Array)) return false;
    return this.props.actionData.map((value, index) => (this.types[value.type](value, index)));
  }

  listRender() {
    if (!(this.props.actionData instanceof Array)) return false;
    return (
      <ul>
        {
          this.props.actionData.map((value, index) => {
            if (!value.buttons) return false;
            value.buttons[0].title = value.title;
            return (
              <li key={index} title={value.title}>
                {this.types[value.buttons[0].type](value.buttons[0])}
              </li>
            );
          })
        }
      </ul>
    )
  }

  rateRender() {
    return (
      <>
      <Rate />
      <ButtonPrint config={this.props.config}/>
      </>
    );
  }

  getAction() {
    return this.actions[this.props.actionType]();
  }

  checkHide() {
    let hasHide = false;

    if (this.props.actionType === "rate") return false;

    this.props.actionData.forEach(value => {
      if (value.hide) hasHide = true;
    });

    return hasHide;
  }

  render() {
    if (this.checkHide()) return false;

    return (
      <div className="chat-action">
        {this.getAction()}
      </div>
    )
  }
};

const mapStateToProps = state => ({
  messages: state.messages,
  conversation: state.conversation,
});

const mapDispatchToProps = dispatch => ({
  sendMessage: message => dispatch(sendMessage(message, "client")),
  setConversation: conversation => dispatch(setConversation(conversation))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatAction);