import { SEND_MESSAGE, SAVE_CONVERSATION, SET_CONVERSATION } from "../constants/actionCreators";
import { clearConversation, toggleLoading } from "./app";
import SpeechService from "../services/speech";
import UserInfo from "../services/user-info";
import RateIteration from "../services/rate-iteration";

import RequestHelper from "../helpers/requestHelper";

let controller = new window.AbortController();
let signal = controller.signal;

export const sendMessage = (messageObj, from = "chat") => (dispatch, getState) => {
  const messageData = {};
  if (messageObj.context) {
    messageObj.context.session_nome = localStorage.getItem("userName");
    messageObj.context.session_cpf = localStorage.getItem("cpf");
    messageObj.context.session_sysId = localStorage.getItem("user_sysId");
    messageObj.context.ssoResponse = JSON.parse(
      localStorage.getItem("ssoResponse")
    );
    dispatch(saveConversation(messageObj.context));
  }

  if (from === "client") {
    controller.abort();
    messageData.userInfo = UserInfo.getInfo();
  }

  if(from === 'chat') {
    messageData.iterationRate = 'validate';
  }

  if(messageObj.actions) {
    if(messageObj.actions.interaction) 
      messageData.iterationRate = 'no-evaluate';
  }

  const messageFromApp = messageObj.message || "";
  messageData.textContent = messageFromApp.replace(/<\/?[^>]+(>|$)/g, "").replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

  messageData.context = getState().conversation;
  messageData.text = messageObj.message;
  messageData.from = from;
  messageData.actions = messageObj.actions;
  messageData.id = messageObj.id;
  messageData.operator = messageObj.operator;
  // attachment_button que vem do Operator/QueueManager em msg de sistema
  if(messageObj.attachment_button) {
    messageData.attachmentAllowedByOperator = messageObj.attachment_button
  }

  let d;
  try {
    d = new Date(messageObj.date || Date.now());
  } finally {
    if (!(d instanceof Date && !isNaN(d))) d = new Date();
  }
  let h = `${d.getHours()}`.padStart(2, '0')
  let m = `${d.getMinutes()}`.padStart(2, '0')
  let s = `${d.getSeconds()}`.padStart(2, '0')
  messageData.date = h + ":" + m + ":" + s;
  
  if (messageObj.app) {
    messageData.from = "app";
  }

  if(messageObj.file) {
    messageData.file = messageObj.file
  }

  dispatch({
    type: SEND_MESSAGE,
    message: messageData
  });

  if (messageObj.closed) {
   dispatch(clearConversation());
  }
};

export const sendAudio = (blob, callbackFn) => dispatch => {
  const speech = new SpeechService();
  controller = new window.AbortController();
  signal  = controller.signal;
  dispatch(toggleLoading(true));
  speech.toText(blob, signal)
    .then(RequestHelper.handleResponse)
    .then( res => {
      dispatch(toggleLoading(false));
      callbackFn(res.transcription);
    })
    .catch( err => {
      dispatch(toggleLoading(false))
    })
  ;
};

export const sendEvaluation = (type, id) => dispatch => {
  const rate = new RateIteration();

  const obj = {
    interactionId: id,
    interactionEvaluation: {
      value: type
    }
  }

  rate.sendEvaluation( obj )
    .then( RequestHelper.handleResponse )
    .catch( err => {
      console.log(err);
    })
};

export const saveConversation = context => ({
  type: SAVE_CONVERSATION,
  context
});

export const setConversation = conversation => ({
  type: SET_CONVERSATION,
  conversation
});