import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import MediaStreamRecorder from "msr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sanitize from "sanitize-html";
import {
  faMicrophone,
  faMicrophoneAltSlash,
  faTimes,
  faSpinner,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import { toggleSttActive } from "../../actions/app";
import { sendMessage, sendAudio } from "../../actions/message";

import ColorHelper from "../../helpers/colorHelper";

import UserInfo from "../../services/user-info";

class ChatFooter extends Component {
  userInfo = UserInfo.getInfo();
  cancelColor = "red";
  buttonSize = "xs";
  mediaContraints = { audio: true };
  recorder = null;
  stream = null;
  audioChunks = [];
  recorderConfig = {
    mimeType: "audio/wav",
    sampleRate: "44100",
    audioChannels: 1,
  };
  colors = new ColorHelper(
    isEmpty(this.props.config.footerColor)
      ? "#abb8c3"
      : this.props.config.footerColor,
    { brightenAmount: 90, darkenAmount: 75 }
  );
  timer = undefined;
  sended = false;
  fullMessage = "";
  state = {
    message: "",
    inputDisabled: false,
  };

  startUserMedia = (stream) => {
    this.stream = stream;
    this.recorder = new MediaStreamRecorder(this.stream);
    this.stream.getAudioTracks()[0].onmute = this.handleSilence;
    const { mimeType, sampleRate, audioChannels } = this.recorderConfig;
    this.recorder.mimeType = mimeType;
    this.recorder.sampleRate = sampleRate;
    this.recorder.audioChannels = audioChannels;
    this.recorder.ondataavailable = (blob) => {
      this.audioChunks.push(blob);
    };
  };

  constructor(props) {
    super(props);
    this.nl2br = this.nl2br.bind(this);
    this.setTimerAndSendMessage = this.setTimerAndSendMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.sendNewOperatorMessageResponse =
      this.sendNewOperatorMessageResponse.bind(this);
    this.startUserMedia = this.startUserMedia.bind(this);
    this.handleUpdateTextArea = this.handleUpdateTextArea.bind(this);
    this.handleSilence = this.handleSilence.bind(this);
  }

  saveContent(value) {
    this.setState({
      ...this.state,
      message: sanitize(value),
    });
  }

  sendWebSocketObject = (message) =>
    JSON.stringify({
      text: this.nl2br(message),
      context: !isEmpty(this.props.conversation) ? this.props.conversation : {},
      infosUser: this.userInfo,
    });

  nl2br = (text) => {
    return text.split("\n").join("<br>");
  };

  sendMessage() {
    localStorage.setItem("last-activity", Date.now());
    if (this.state.message.trim().length > 0) {
      // this.props.sendMessage({ message: this.nl2br(this.state.message) });
      if (this.props.handleSend) {
        this.props.handleSend();
        this.sended = true;
        this.fullMessage = `${this.fullMessage} ${this.state.message}`;
        this.setTimerAndSendMessage(100);
      }
      this.saveContent("");
    }
  }

  handleEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (
        this.props.ws.io.readyState !== "open" ||
        this.state.message.trim() === ""
      ) {
        return false;
      }
      this.sendMessage();
      this.setTimerAndSendMessage(100);
    }
  };

  handleChange = (e) => {
    const element = e.currentTarget;
    this.saveContent(element.value);
    this.setTimerAndSendMessage(50);
  };

  sendNewOperatorMessageResponse = () => {
    const unreadMessages = JSON.parse(
      localStorage.getItem("session-unread-messages") || "[]"
    );
    if (unreadMessages.length > 0) {
      localStorage.setItem("session-unread-messages", "[]");
      const { conversation_id } = this.props.messages[1].context;
      unreadMessages.forEach((message) => {
        this.props.ws.emit(
          "newOperatorMessageResponse",
          JSON.stringify({
            message_id: message,
            conversation_id,
            seen: true,
          })
        );
      });
    }
  };

  setTimerAndSendMessage(time) {
    if (!this.sended) return false;
    if (this.timer !== undefined) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.props.app.isCustomerSendMessageToOperator) {
        this.props.ws.emit(
          "customerSendMessage",
          this.sendWebSocketObject(this.fullMessage)
        );
      } else if (this.props.app.isCustomerSendMessageToOperatorServiceNow) {
        this.props.ws.emit(
          "customerSendMessageToServiceNow",
          this.sendWebSocketObject(this.fullMessage)
        );
      } else {
        this.props.ws.emit(
          "customerSendMessageToBot",
          this.sendWebSocketObject(this.fullMessage)
        );
      }
      this.sended = false;
      this.fullMessage = "";
    }, time);
  }

  static getDerivedStateFromProps = (props, state) => {
    const newState = state;
    if (props.inputDisabled) {
      newState.inputDisabled = props.inputDisabled;
    }

    return newState;
  };

  stopRecording() {
    this.recorder.stop();
    this.props.toggleSttActive(false);
  }

  handleCapture = (e) => {
    if (!this.props.app.conversation) return false;
    this.props.toggleSttActive(true);
    this.recorder.start();
  };

  handleCancelCapture = (e) => {
    this.stopRecording();
    this.audioChunks = [];
    this.props.toggleSttActive(false);
  };

  handleSendCapture = (e) => {
    if (!this.props.app.conversation) return false;
    this.stopRecording();
    this.props.sendAudio(this.audioChunks, this.handleUpdateTextArea);
    this.audioChunks = [];
  };

  handleSilence = () => {
    this.stopRecording();
    this.props.sendAudio(this.audioChunks, this.handleUpdateTextArea);
    this.audioChunks = [];
  };

  handleUpdateTextArea(text) {
    if (text.trim() === "") {
      return false;
    }
    if (this.state.message.trim().length > 0) {
      text = `${this.state.message.trim()} ${text}`;
    }
    this.setState(
      {
        ...this.state,
        message: text,
      },
      () => {
        this.sendMessage();
      }
    );
  }

  componentDidMount() {
    if (this.props.config.isSpeechToTextActive) {
      navigator.mediaDevices
        .getUserMedia(this.mediaContraints)
        .then(this.startUserMedia)
        .catch((e) => {
          console.log("No live audio input", e);
        });
    }
  }

  getSentButton() {
    const colors = new ColorHelper(
      isEmpty(this.props.config.footerColor)
        ? "#abb8c3"
        : this.props.config.footerColor
    );
    if (
      this.userInfo.browser === "Internet Explorer" &&
      this.userInfo.version === "11.0"
    )
      return false;
    if (!this.isReadyToEnable()) return null;
    const sendBtn = (
      <button
        alt="Enviar Mensagem"
        title="Enviar"
        onClick={(e) => this.sendMessage()}
      >
        <FontAwesomeIcon
          icon={faPaperPlane}
          size={this.buttonSize}
          color={new ColorHelper(
            colors.getColorGuide().primaryColor
          ).getAdaptativeColor()}
        />
      </button>
    );
    return <span className="send-btn">{sendBtn}</span>;
  }

  getStt() {
    const colors = new ColorHelper(
      isEmpty(this.props.config.footerColor)
        ? "#abb8c3"
        : this.props.config.footerColor
    );
    if (
      this.userInfo.browser === "Internet Explorer" &&
      this.userInfo.version === "11.0"
    )
      return false;
    if (!this.props.config.isSpeechToTextActive) return null;
    if (!this.isReadyToEnable()) return null;
    const activateMic = (
      <button
        alt="Ativar Microfone"
        title="Microfone"
        onClick={(e) => this.handleCapture(e)}
      >
        <FontAwesomeIcon
          icon={faMicrophone}
          size={this.buttonSize}
          color={new ColorHelper(
            colors.getColorGuide().primaryColor
          ).getAdaptativeColor()}
        />
      </button>
    );
    const cancel = (
      <button
        alt="Cancelar"
        title="Cancelar"
        onClick={(e) => this.handleCancelCapture(e)}
      >
        <FontAwesomeIcon
          icon={faTimes}
          size={this.buttonSize}
          color={this.cancelColor}
        />
      </button>
    );
    const recording = (
      <button
        className="active"
        alt="Gravando"
        title="Gravando"
        onClick={(e) => this.handleSendCapture(e)}
      >
        <FontAwesomeIcon
          icon={faMicrophoneAltSlash}
          size={this.buttonSize}
          color={new ColorHelper(
            colors.getColorGuide().primaryColor
          ).getAdaptativeColor()}
        />
      </button>
    );
    const loading = (
      <div>
        <FontAwesomeIcon
          className="loading"
          icon={faSpinner}
          size={this.buttonSize}
          style={{
            color: new ColorHelper(
              colors.getColorGuide().primaryColor
            ).getAdaptativeColor(),
          }}
        />
      </div>
    );
    let sttActionButtons = (
      <div>
        {!this.props.app.sttActive ? (
          activateMic
        ) : (
          <div>
            {cancel}
            {recording}
          </div>
        )}
      </div>
    );

    if (this.props.app.loading) {
      sttActionButtons = loading;
    }

    return <span className="stt">{sttActionButtons}</span>;
  }

  isReadyToEnable = () => {
    return this.props.app.websocket && this.props.messages.length > 1;
  };

  getPlaceholder = (placeholderText) => {
    let placeholder = this.props.placeholder;
    if (!this.isReadyToEnable()) {
      placeholder = "Carregando...";
    }
    if (!this.props.app.websocket && this.props.messages.length > 0) {
      placeholder = placeholderText;
    }
    return placeholder;
  };

  render() {
    const { app, placeholder } = this.props;
    const colors = this.colors.getColorGuide();
    let footerClassName = `footer ${app.minimize ? "omni-hide" : ""}`;
    let loadingClass = `upload-attachment ${
      this.colors.getColor().isLight() ? "dark" : "light"
    }`;
    if (this.props.app.attachLoading) loadingClass += " show";

    footerClassName = `${footerClassName} ${
      !this.isReadyToEnable() ? "disabled" : ""
    }`;
    let displayInput = app.inputActive ? {} : { display: "none" };

    // if (this.isReadyToEnable() && !app.inputActive) {
    //   footerClassName = "disabled"
    //   displayInput = { display: "none" };
    // }

    // window.addEventListener('beforeunload', (event) => {
    //   console.log('onbeforeunload');
    //   event.returnValue = 'Quer realmente encerrar o atendimento?'

    // });
    // window.addEventListener('unload', (event) => {
    //   console.log('onunload')

    // });
    return (
      <div className="footer-wrap">
        <div
          className={footerClassName}
          style={{ ...displayInput, backgroundColor: colors }}
        >
          {this.getStt()}
          <div className={loadingClass}></div>
          <textarea
            placeholder={this.getPlaceholder(placeholder)}
            ref={(textarea) => (this.inputText = textarea)}
            name="message"
            onChange={this.handleChange}
            onKeyDownCapture={this.handleEnter}
            onInput={this.sendNewOperatorMessageResponse}
            onFocus={this.sendNewOperatorMessageResponse}
            value={this.state.message}
            disabled={!this.isReadyToEnable()}
            className={
              this.colors.getColor().isLight() ? "dark-text" : "light-text"
            }
          />
          {this.getSentButton()}
        </div>
        <div hidden={!this.props.config.isCustomizedFooter}>
          <span className="footer-text">{this.props.config.footerText}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  app: state.app,
  config: state.config,
  conversation: state.conversation,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSttActive: (toggle) => dispatch(toggleSttActive(toggle)),
  sendMessage: (message) => dispatch(sendMessage(message, "client")),
  sendAudio: (blob, callbackFn) => dispatch(sendAudio(blob, callbackFn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatFooter);
