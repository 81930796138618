import React, { Component } from "react";
import { connect } from "react-redux";
import { Howl, Howler } from 'howler';
import { isEmpty, /* cloneDeep, */ /*last*/ } from "lodash";

import { setConversation, sendMessage } from '../../actions/message';

import { loadedTTSSound } from '../../actions/app';

import ChatFiles from './chat-files';
import SpeechService from "../../services/speech";
import ColorHelper from "../../helpers/colorHelper";
import RequestHelper from "../../helpers/requestHelper";
import ChatActions from "../../components/chat/chat-actions";
import RateIteration from '../rate-iteration/rate-iteration';
import ChatUpload from "./chat-upload";

class ChatMessage extends Component {
  extensionRegEx = new RegExp('\\.([^/#?]+)([#?][^/]*)?$');

  whitelabelExtensions = ["pdf", "docx", "doc"];

  speechService = new SpeechService()

  howlConfig = {
    mobileAutoEnable: true,
    format: ['mp3']
  }

  colorsBot = new ColorHelper(this.props.config.botTextBalloonColor || '#0066cc', { brightenAmount: 85, darkenAmount: 50 })
  textColorBot = new ColorHelper(this.props.config.botTextColor || '#000000', { brightenAmount: 85, darkenAmount: 50 })

  colorsClient = new ColorHelper(this.props.config.customerTextBalloonColor || '#0066cc', { brightenAmount: 85, darkenAmount: 50 })
  textColorClient = new ColorHelper(this.props.config.customerTextColor || '#000000', { brightenAmount: 85, darkenAmount: 50 })

  lastUploadedFile = ''

  renderAvatar() {
    if (this.props.message.from === "client" || !this.props.chatAvatar) {
      return <img className="avatar" src={this.props.config.userAvatarUrl} alt="User" />;
    } else if (this.props.message.from === "operator" || !this.props.chatAvatar) {
      return <img className="avatar-client" src={this.props.config.welcomeImageUrl} alt="Operador" />;
    }
    return (
      <img className="avatar" src={this.props.config.botAvatarUrl} alt="Bot" />
    );
  }

  addLinkClass(element) {
    const link = element.querySelector("a");
    if (!link) return false;
  }

  linkExtension(url) {
    let extension = null;
    this.extensionRegEx.test(url);
    extension = RegExp.$1;
    if (!this.whitelabelExtensions.includes(extension)) {
      extension = "link";
    }
    return extension;
  }

  isPlayingSound() {
    const soundsPlaying = Howler._howls.filter((sound) => sound.playing() === true);
    return soundsPlaying.length > 0;
  }

  prepareAudio(audio) {
    let playingPooling;
    const config = { ...this.howlConfig, src: [audio] }
    const sound = new Howl(config);
    sound.on('load', () => {
      playingPooling = setInterval(() => {
        if (!this.isPlayingSound() && this.props.message.from === "chat") {
          sound.seek(0).play();
          this.props.loadedTTSSound(sound);
        }
      }, 1000);
    });

    sound.on('play', () => {
      clearInterval(playingPooling);
    });

    sound.on('end', () => {
      sound.unload();
    });
  }

  getAudio() {
    const promise = this.speechService.toAudio(this.props.message.textContent);
    promise
      .then(RequestHelper.handleResponse)
      .then(audioFile => {
        this.prepareAudio(audioFile.audio);
      })
      ;
  }

  componentDidMount() {
    this.speechService.HEADERS = new Headers({
      "Content-type": "application/json"
    });
    this.addLinkClass(this.htmlText);
    if (this.props.app.ttsActive) {
      this.getAudio();
    }
  }

  attachmentBtn() {
    return <ChatUpload config={this.props.config} message={this.props.message} ws={this.props.ws}></ChatUpload>
  }

  buildSpeechBubbleBot(data) {
    const { text, actions, iterationRate, id, context, date } = this.props.message;
    let botName = context.bot_name ? context.bot_name : `${this.props.config.assistantName}`;
    const activeInteractionRate = this.props.config.isConversationRatingActive;
    const hasAttachmentButton = this.props.message.context.attachment_button;
    return <div>
      <div className={data.avatarContainer} >
        {this.renderAvatar()}
      </div>
      <div className={data.contentContainer} style={data.bkStyle}>
        <div className="bot-name-style">{botName}</div> <div className="hour-style">{date}</div>
        <span dangerouslySetInnerHTML={{ __html: text }} ref={el => this.htmlText = el} className="text-link-inner eds-style-text" style={data.txtStyle} />
        {hasAttachmentButton ? this.attachmentBtn() : null}
        {
          isEmpty(actions) ? false : Object.keys(actions).map((action, index) => {
            if (action !== "list" && action !== "buttons" && action !== "quick_replies" && action !== "rate" && action !== 'checklist') return false;
            return (
              <ChatActions
                key={index}
                ws={this.props.ws}
                position={actions.position}
                actionType={action}
                actionData={actions[action]}
                config={this.props.config}
              />
            )
          })
        }
        {activeInteractionRate ? <RateIteration config={this.props.config} action={iterationRate} position={this.props.position} id={id} /> : false}
      </div>
    </div>
  }

  buildSpeechBubbleOperator(data) {
    const { text, actions, date, operator, attachmentAllowedByOperator, /*file*/ } = this.props.message;
    //TODO: @martini bug#23895 verificar porque está chegando fullName no snippet. 
    const operatorFirstName = operator.trim().split(' ')
    return <div>
      <div className={data.avatarContainer} >
        {this.renderAvatar()}
      </div>
      <div className={data.contentContainer} style={data.bkStyle}>
        <div className="bot-name-style">{operatorFirstName[0]}</div> <div className="hour-style">{date}</div>
        <span dangerouslySetInnerHTML={{ __html: text }} ref={el => this.htmlText = el} className="text-link-inner eds-style-text" style={data.txtStyle} />
        {attachmentAllowedByOperator ? this.attachmentBtn() : null}
        {
          isEmpty(actions) ? false : Object.keys(actions).map((action, index) => {
            if (action !== "list" && action !== "buttons" && action !== "quick_replies" && action !== "rate" && action !== 'checklist') return false;
            return (
              <ChatActions
                key={index}
                ws={this.props.ws}
                position={actions.position}
                actionType={action}
                actionData={actions[action]}
                config={this.props.config}
              />
            )
          })
        }
      </div>
    </div>
  }

  buildSpeechBubbleCustomer(data) {
    const { text, actions, file, context, date } = this.props.message;
    let name = context.session_nome && context.session_nome !== '' ? context.session_nome : 'Usuário';
    if (name === 'null') {
      name = 'Usuário';
    }
    return <div>
      <div className={data.contentContainer} style={data.bkStyle}>
        <div className="cust-hour-style">{date}</div><div className="cust-name-style">{name}</div>
        <div dangerouslySetInnerHTML={{ __html: text }} ref={el => this.htmlText = el} className="text-link-inner eds-style-text" style={data.txtStyle} ></div>
        {
          file ? <ChatFiles file={file} /> : false
        }
        {
          isEmpty(actions) ? false : Object.keys(actions).map((action, index) => {
            if (action !== "list" && action !== "buttons" && action !== "quick_replies" && action !== "rate" && action !== 'checklist') return false;
            return (
              <ChatActions
                key={index}
                ws={this.props.ws}
                position={actions.position}
                actionType={action}
                actionData={actions[action]}
                config={this.props.config}
              />
            )
          })
        }
      </div>
      <div className={data.avatarContainer} >
        {this.renderAvatar()}
      </div>
    </div >
  }

  buildSpeechSystem(data) {
    const { text /* date */ } = this.props.message;
    return <div>
      <div className={data.contentContainer}>
        {/* <div className="cust-hour-style">{date}</div><div className="cust-name-style">{name}</div> */}
        <div dangerouslySetInnerHTML={{ __html: text }} ref={el => this.htmlText = el} className="text-link-inner" style={data.txtStyle} ></div>
      </div>
    </div >
  }

  renderMessage(from, message) {
    switch (from) {
      case 'chat':
        return this.buildSpeechBubbleBot(message);

      case 'operator':
        return this.buildSpeechBubbleOperator(message);

      case 'system':
        return this.buildSpeechSystem(message);

      default:
        return this.buildSpeechBubbleCustomer(message);
    }
  }

  render() {
    const { from /*, file, userInfo, context */ } = this.props.message;
    let messageClassName = `message-item ${from}`;
    let avatarContainer = `avatar-container-${from}`;
    let contentContainer = `content-container-${from}`;
    let opt = from;

    if (from === "app") {
      messageClassName = 'message-item chat';
      avatarContainer = 'avatar-container-chat';
      contentContainer = 'content-container-chat';
      opt = 'chat';
    }

    if (from === 'operator') {
      // TODO: As configurações de estilo são iguais para o bot e o humano?
      messageClassName = 'message-item chat';
      avatarContainer = 'avatar-container-chat';
      contentContainer = 'content-container-chat';
    }

    let txtStyle = null
    let bkStyle = null

    // TODO: As configurações de estilo são iguais para o bot e o humano?
    switch (from) {
      case 'chat':
      case 'operator':
        txtStyle = {
          color: this.textColorBot.getColor(),
        }
        bkStyle = {
          backgroundColor: this.colorsBot.getColor().setAlpha(0.2),
        }
        break;

      case 'system':
        // txtStyle = {
        //   fontWeight: 'bold'
        // }
        break;

      default:
        txtStyle = {
          color: this.textColorClient.getColor()
        }
        bkStyle = {
          backgroundColor: this.colorsClient.getColor().setAlpha(0.2),
        }
    }

    let data = {
      avatarContainer: avatarContainer,
      contentContainer: contentContainer,
      txtStyle,
      bkStyle
    }

    return (
      <li className={messageClassName} style={bkStyle} >
        {this.renderMessage(opt, data)}
      </li>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  config: state.config,
  messages: state.messages
});

const mapDispatchToProps = dispatch => ({
  setConversation: conversation => dispatch(setConversation(conversation)),
  sendMessage: conversation => dispatch(sendMessage(conversation)),
  loadedTTSSound: sound => dispatch(loadedTTSSound(sound)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage);


  // TODO: o que isso faz ou fazia ?
  // fileResponseType(file) {
  //   if (!file || !file.link) {
  //     return this.props.sendMessage({
  //       message: this.props.config.loadingFileError === '' ? "Desculpe, ocorreu um erro ao carregar este arquivo.<br /> Por gentileza, tente novamente." : this.props.config.loadingFileError
  //     });
  //   }
  //   const imageTypes = ['jpeg', 'jpg', 'png', 'gif', 'svg'];
  //   const fileType = file.name.split('.');
  //   const isImage = imageTypes.indexOf(last(fileType));
  //   const uploadedFile = file.link.split('?')[0];
  //   let fileView;
  //   if (isImage) {
  //     fileView = `<img src='${uploadedFile}' alt='${file.name}' />`
  //   } else if (['pdf'].indexOf(last(fileType))) {
  //     fileView = `<img src='${PDFIcon}' alt='${file.name}' /> ${file.name}`
  //   } else if (['csv'].indexOf(last(fileType))) {
  //     fileView = `<img src='${CSVIcon}' alt='${file.name}' /> ${file.name}`
  //   } else if (['doc','docx'].indexOf(last(fileType))) {
  //     fileView = `<img src='${DOCIcon}' alt='${file.name}' /> ${file.name}`
  //   } else if (['xls','xlsx'].indexOf(last(fileType))) {
  //     fileView = `<img src='${PDFIcon}' alt='${file.name}' /> ${file.name}`
  //   } else if (['mp3', 'wav', 'aiff', 'au', 'pcm', 'flac', 'alac', 'acc'].indexOf(last(fileType))) {
  //     fileView = `<img src='${AudioIcon}' alt='${file.name}' /> ${file.name}`
  //   } else if (['avi','mp4','wmv', 'mov', 'qt', 'mkv', 'avchd'].indexOf(last(fileType))) {
  //     fileView = `<img src='${VideoIcon}' alt='${file.name}' /> ${file.name}`
  //   }

  //   return `<a href='${uploadedFile}' target='_blank'>${fileView}</a>`;
  // }

  // attachmentBtn() {
  //   return <ChatUpload config={this.props.config} message={this.props.message} ws={this.props.ws}></ChatUpload>
  // }

   // TODO: Verificar esse socket send antes de trabalhar com arquivos...
    // if (file && file.link !== this.lastUploadedFile && !file.sent) {
    //   this.lastUploadedFile = file.link;
    //   this.props.ws.send(JSON.stringify({ context, infosUser: userInfo, text: this.fileResponseType(file), attachment: { size: file.size, name: file.name, url: file.link, mimeType: file.type } }));

    //   setTimeout(() => {
    //     const newMessages = cloneDeep(this.props.messages);
    //     newMessages[this.props.position].file.sent = true;
    //     this.props.setConversation(newMessages);
    //   }, 200);
    // }
