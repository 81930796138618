import UploadService from '../services/upload';

export const upload = (file, data) => {
  const upload = new UploadService();

  return upload.upload(file, data)
    .then( res => {
      return res;
    })
    .catch( err => {
      console.log(err);
    })
  ;
}