import React, { Component } from "react";
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";

import ChatMessage from "./chat-message";
import ChatFooter from "./chat-footer";
import getEnvironment from "../../constants/env";
import UserInfo from "../../services/user-info";

import { sendMessage, saveConversation, setConversation } from "../../actions/message";
import { toggleChat, toggleWebsocket, toggleClientInput, closeConversation, toggleWebsocketMgsToOperator, toggleAllowAttachment, toggleWebsocketMgsToOperatorServiceNow, toggleAttachLoad, toggleAttachAvailable } from "../../actions/app";
import ConversationHelper from "../../helpers/conversation";
import ChatSession from "../../helpers/chatSession";
import SoundMessage from '../../assets/message.mp3';

class ChatContent extends Component {

  reconnected = false;
  validationSession = false;
  currentSessionValidator = undefined;
  retryReconnect = undefined;

  constructor(props) {
    super(props);
    this.state = {
      inputDisabled: false,
      // placeholder: "Escreva uma mensagem"
      placeholder: ""
    };
    this.handleWebSocketOpen = this.handleWebSocketOpen.bind(this);
    this.handleWebSocketWatsonMessage = this.handleWebSocketWatsonMessage.bind(this);
    this.handleWebSocketActionResponse = this.handleWebSocketActionResponse.bind(this);
    this.handleWebSocketCustomerMessage = this.handleWebSocketCustomerMessage.bind(this);
    this.handleWebSocketOperatorMessage = this.handleWebSocketOperatorMessage.bind(this);
    this.handleWebSocketSystemMessage = this.handleWebSocketSystemMessage.bind(this);
    this.handleWebSocketChatEnded = this.handleWebSocketChatEnded.bind(this);
  }

  environment = getEnvironment()

  ws = socketIOClient(this.environment.REACT_APP_WEBSOCKET_URL, { transports: ['websocket'] });
  audio = new Audio(SoundMessage)

  conversation = ConversationHelper;

  registerWebSocketEvents() {
    this.ws.on('connect', () => {
      console.debug("on connect");
      this.handleWebSocketOpen();
    })

    this.ws.on('watson-message', (responseText) => {
      console.debug("on watson-message");
      // A Helô tá muito esperta e respondendo muito rápido kkkk
      setTimeout(() => {
        this.handleWebSocketWatsonMessage({ data: responseText });
        if (this.props.app.msgSoundActive) this.audio.play();
      }, 1000);
    })

    this.ws.on('actionResponse', (responseText) => {
      console.debug("on action response");
      this.handleWebSocketActionResponse({ data: responseText });
    })

    this.ws.on('newCustomerMessage', (responseText) => {
      console.debug("on newCustomerMessage");
      this.handleWebSocketCustomerMessage({ data: responseText });
    })

    this.ws.on('newOperatorMessage', (responseText) => {
      console.debug("on newOperatorMessage");
      this.handleWebSocketOperatorMessage({ data: responseText });
      if (this.props.app.msgSoundActive) this.audio.play();
    });

    this.ws.on('newSystemMessage', (responseText) => {
      console.debug("on newSystemMessage");
      this.handleWebSocketSystemMessage({ data: responseText });
      if (this.props.app.msgSoundActive) this.audio.play();
    });

    this.ws.on('chatEnded', (responseText) => {
      console.debug("on chatEnded");
      this.handleWebSocketChatEnded({ data: responseText });
      if (this.props.app.msgSoundActive) this.audio.play();
    });

    this.ws.on('disconnect', (closeEvent) => {
      console.debug("on disconnect");
      console.log("Websocket Closed: ", closeEvent);
      /**
       * O SOCKET.IO ABSTRAI O CÓDIGO DE RECONEXÃO, NÃO SENDO NECESSÁRIO FAZÊ-LO MANUALMENTE
       * Zava (19/06/2020)
       */
      if (closeEvent === 'io client disconnect') this.closeWebSocket();
    })

  }

  scrollToBottom() {
    if (!this.chat) return false;
    if (this.chat.childNodes.length > 1) {
      const scrollHeight = this.chat.childNodes[this.chat.childNodes.length - 2].scrollHeight;
      this.chat.scrollTop = this.chat.scrollHeight - scrollHeight;
    }
  }

  getParams(url) {
    const params = {};
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  closeWebSocket = () => {
    if (this.ws) {
      this.ws.close();
      this.props.toggleWebsocket(false);
    }
  }

  handleWebSocketOpen = () => {
    console.debug("handleWebSocketOpen()");

    // Garante que uma nova aba receba as atualizações do conversation_id
    const sessionConfig = JSON.parse(localStorage.getItem('session-config') || '{}');
    if (sessionConfig.conversation_id)
      this.ws.emit('subscribeConversation', sessionConfig.conversation_id);

    this.props.toggleWebsocket(true);
    this.setState({
      ...this.state,
      // placeholder: "Escreva uma mensagem"
      placeholder: ""
    });
  }

  handleWebSocketActionResponse(message) {
    localStorage.setItem("last-activity", Date.now());
    let messageData;
    try {
      messageData = JSON.parse(message.data);
      if (messageData) {
        localStorage.setItem('action-context', JSON.stringify(messageData));
        this.props.toggleAttachLoad(true);
      }
    }
    catch (e) {
      console.error("Can't parse JSON on WebSocket");
    }
  }

  handleWebSocketWatsonMessage(message) {
    localStorage.setItem("last-activity", Date.now());
    let messageData;
    try {
      messageData = JSON.parse(message.data);
    }
    catch (e) {
      console.error("Can't parse JSON on WebSocket");
    }
    if (messageData) {
      localStorage.setItem("last-activity", Date.now());
      this.props.toggleAttachLoad(false);
      if (messageData.context && messageData.context.attachment_button) {
        this.props.toggleAttachAvailable(true);
      }
      this.props.sendMessage(this.conversation.parse(messageData, this.props.messages));
      if (messageData.context && messageData.context.block_client_input === this.props.app.inputActive) {
        this.props.toggleClientInput(!messageData.context.block_client_input);
        localStorage.setItem('session-inputActive', this.props.app.inputActive);
      }
      console.debug('set session-inputActive = ' + this.props.app.inputActive);
      if (messageData.context && messageData.context.end_conversation) {
        this.closeWebSocket();
        if (this.props.config.isConversationRatingActive) {
          setTimeout(() => {
            this.props.sendMessage(this.conversation.parse({
              message: this.props.config.ratingRequestMessage === ''
                ? "Como você avalia meu atendimento?"
                : this.props.config.ratingRequestMessage,
              rate: true, interaction: true
            }));
          }, 1500);
        }
      }
      this.scrollToBottom();
    }
  }

  handleWebSocketCustomerMessage(message) {
    localStorage.setItem("last-activity", Date.now());
    let messageData;
    try {
      messageData = JSON.parse(message.data);
    }
    catch (e) {
      console.error("Can't parse JSON on WebSocket");
    }
    if (messageData) {
      this.props.sendMessage({ message: messageData.text }, 'client');
    }
  }

  handleWebSocketOperatorMessage(message) {
    localStorage.setItem("last-activity", Date.now());
    let messageData;
    try {
      messageData = JSON.parse(message.data);
    }
    catch (e) {
      console.error("Can't parse JSON on WebSocket");
    }
    if (messageData) {
      // Mensagens não lidas
      const unreadMessages = JSON.parse(localStorage.getItem('session-unread-messages') || '[]');
      unreadMessages.push(messageData.messageId);
      localStorage.setItem('session-unread-messages', JSON.stringify(unreadMessages));

      this.props.toggleAttachAvailable(messageData.attachment_button);
      this.props.toggleAllowAttachment(messageData.allow_attachment);
      console.log(messageData);

      // Converte a mensagem para o padrão da aplicação
      const messageDataAux = {
        id: messageData.messageId,
        date: messageData.timestamp,
        message: messageData.text,
        operator: messageData.operatorName,
        allow_attachment: messageData.allow_attachment,
        attachment_button: messageData.attachment_button
      };

      this.props.sendMessage(messageDataAux, 'operator');
      this.scrollToBottom();
    }
  }

  handleWebSocketSystemMessage(message) {
    localStorage.setItem("last-activity", Date.now());
    let messageData;
    try {
      messageData = JSON.parse(message.data);
    }
    catch (e) {
      console.error("Can't parse JSON on WebSocket");
    }
    if (messageData) {
      if (messageData.humanChat) {
        this.props.toggleWebsocketMgsToOperator(true);
        localStorage.setItem("isCustomerSendMessageToOperator", true);
      }

      if (messageData.transferServiceNow) {
        this.props.toggleWebsocketMgsToOperatorServiceNow(true);
      }
      // Converte a mensagem para o padrão da aplicação
      const messageDataAux = {
        id: messageData.messageId,
        date: messageData.timestamp,
        message: messageData.text,
      };
      this.props.sendMessage(messageDataAux, 'system');
      if (messageData.block_client_input === this.props.app.inputActive) {
        this.props.toggleClientInput(!messageData.block_client_input);
        localStorage.setItem('session-inputActive', this.props.app.inputActive);
      }

      if (messageData.isExpiredConversation) {
        this.ws.emit(
          "expiredByUserInactivity",
          messageData.conversationId,
          () => {
            this.closeWebSocket();
            if (this.props.config.isConversationRatingActive === "true") {
              setTimeout(() => {
                this.props.sendMessage(
                  this.conversation.parse({
                    message:
                      this.props.config.ratingRequestMessage === ""
                        ? "Como você avalia meu atendimento?"
                        : this.props.config.ratingRequestMessage,
                    rate: true,
                    interaction: true,
                  })
                );
              }, 1000);
            }
          }
        );
      }
      this.scrollToBottom();
    }
  }


  getName() {
    switch (this.props.config.assistantName) {
      case "Helô":
        return "INSS";

      default:
        return this.props.config.assistantName;
    }
  }

  handleWebSocketChatEnded(message) {
    let messageData;
    try {
      messageData = JSON.parse(message.data);
    }
    catch (e) {
      console.error("Can't parse JSON on WebSocket");
    }
    if (messageData) {
      // Reseta o estado inicial de falar com bot em uma próxima conversa nova
      this.props.toggleWebsocketMgsToOperator(false);
      localStorage.setItem("isCustomerSendMessageToOperator", false);
      this.props.toggleWebsocketMgsToOperatorServiceNow(false);

      this.ws.emit('unsubscribeConversation', messageData.conversationId, () => {
        this.closeWebSocket();
        if (messageData.silent) {
          this.props.closeChat();
        } else if (this.props.config.isConversationRatingActive) {
          setTimeout(() => {
            this.props.sendMessage({
              date: Date.now(),
              message:
                this.props.config.introducingRatingMessage
                  ? this.props.config.introducingRatingMessage
                  : "Nossa avaliação será exibida em instantes.",
              operator: this.getName(),
            }, 'operator');
            this.scrollToBottom();
            setTimeout(() => {
              this.props.sendMessage({
                date: Date.now(),
                message:
                  this.props.config.operatorMessageRating === ""
                    ? "Como você avalia nosso atendimento?"
                    : this.props.config.operatorMessageRating,
                operator: this.getName(),
                actions: {
                  rate: true,
                  interaction: true
                }
              }, 'operator');
              this.scrollToBottom();
            }, 1500);
          }, 500);
        }
      })
    }
  }

  componentWillUnmount() {
    this.closeWebSocket();
    this.validationSession = false;
    clearInterval(this.currentSessionValidator);
  }
  componentDidMount() {
    this.registerWebSocketEvents();

    // if (ChatSession.checkSession()) {
    //   const localSessionData = ChatSession.getSessionData();
    //   const data = {};
    //   this.props.saveConversation(localSessionData.config);
    //   this.props.setConversation(localSessionData.conversation);
    //   data.validateSession = true;
    //   data.conversation = localSessionData.config.conversation_id;

    //   setTimeout(() => {
    //     this.ws.send(JSON.stringify(data));
    //   }, 1000);
    //   return false;
    // }

    const localSessionData = ChatSession.getSessionData();


    if (ChatSession.checkSession()) {
      this.props.saveConversation(localSessionData.config);
      this.props.toggleWebsocketMgsToOperator(localSessionData.isCustomerSendMessageToOperator);
      this.props.setConversation(localSessionData.conversation);
      // console.log('config:' + localSessionData.config)
      // console.log('messages:' + localSessionData.conversation)
      this.props.toggleClientInput(localStorage.getItem('session-inputActive') === 'true');
      console.debug('get session-inputActive = ' + localStorage.getItem('session-inputActive'));
    } else {
      let data = {};
      let infosUser = UserInfo.getInfo();
      data.infosUser = infosUser;
      data.firstMessage = true;
      this.props.toggleClientInput(true);
      localStorage.setItem('session-inputActive', true);
      data.context = {
        session_nome: localStorage.getItem('userName'),
        session_cpf: localStorage.getItem('cpf'),
        session_token: localStorage.getItem('user_token'),
        session_sysId: localStorage.getItem('user_sysId')
      };
      setTimeout(() => {
        this.ws.emit('customerSendMessageToBot', JSON.stringify(data));
      }, 1000);
    }
    //data.context = { ssoResponse: JSON.parse(localStorage.getItem('ssoResponse')) }

    // if (window.location.href.includes("?")) {
    //   const params = this.getParams(window.location.href)
    //   data.paramsUrl = params
    // }

  }

  componentDidUpdate() {
    const that = this;
    ChatSession.updateMessages(this.props.messages, this.props.conversation);
    setTimeout(() => {
      that.scrollToBottom();
    }, 50)
  }

  // keepSession() {
  //   const data = {};
  //   const localSessionData = ChatSession.getSessionData();
  //   this.validationSession = true;
  //   data.validateSession = true;
  //   data.conversation = localSessionData.config.conversation_id;

  //   this.ws.send(JSON.stringify(data));

  //   this.currentSessionValidator = setInterval(() => {
  //     this.ws.send(JSON.stringify(data));
  //   }, Math.round((this.props.config.sessionTimeout) / 2));
  // }

  // flushSession() {
  //   this.validationSession = false;
  //   clearInterval(this.currentSessionValidator);
  // }

  render() {
    const { messages, app } = this.props;
    const chatContentClassName = `chat-content ${app.minimize ? "omni-hide" : ""}`;
    //if (this.props.app.modal.status && !this.validationSession) this.keepSession();
    //if (!this.props.app.modal.status) this.flushSession();

    return (
      <div className={chatContentClassName}>
        <ul ref={el => this.chat = el} id="chat-list-messages">
          {
            messages.map((message, index) => {
              if (!message.text) return false;
              return (
                <ChatMessage
                  ws={this.ws}
                  key={index}
                  message={message}
                  position={index}
                  chatAvatar={this.props.config.botAvatarUrl}
                />
              );
            })
          }
          <li className="message-item omni-hide" ref={el => this.end = el} />
        </ul>
        <ChatFooter ws={this.ws} handleSend={this.scrollToBottom} placeholder={this.state.placeholder} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  app: state.app,
  config: state.config,
  messages: state.messages,
  conversation: state.conversation || {}
});

const mapDispatchToProps = dispatch => ({
  sendMessage: (message, from) => dispatch(sendMessage(message, from)),
  toggleClientInput: toggle => dispatch(toggleClientInput(toggle)),
  toggleWebsocketMgsToOperator: toggle => dispatch(toggleWebsocketMgsToOperator(toggle)),
  toggleWebsocketMgsToOperatorServiceNow: toggle => dispatch(toggleWebsocketMgsToOperatorServiceNow(toggle)),
  toggleWebsocket: toggle => {
    console.debug("toggleWebsocket = " + toggle)
    dispatch(toggleWebsocket(toggle))
  },
  closeChat: () => {
    dispatch(toggleChat(false))
    dispatch(closeConversation())
  },
  saveConversation: context => dispatch(saveConversation(context)),
  setConversation: conversation => dispatch(setConversation(conversation)),
  toggleAttachLoad: status => dispatch(toggleAttachLoad(status)),
  toggleAttachAvailable: status => dispatch(toggleAttachAvailable(status)),
  toggleAllowAttachment: status => dispatch(toggleAllowAttachment(status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatContent)