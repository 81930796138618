import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import tinycolor from 'tinycolor2';
import { first, cloneDeep } from 'lodash';
import ButtonPrint from '../buttonPrint/buttonPrint'

import { toggleModal } from '../../actions/app';

import Rate from '../rate/rate';

import './modal.scss';

class Modal extends PureComponent {

  state = {
    type: '',
    disabled: true,
  }

  constructor(props) {
    super(props);
    this.getModal = this.getModal.bind(this);
    this.getLastMessageTime = this.getLastMessageTime.bind(this);
  }

  handleClick = (type, e) => {
    this.setState({
      ...this.state,
      disabled: false,
    })
  }

  handleClose = e => {
    this.props.toggleModal(false, '');
    this.props.modal.onClose();
    this.setState({
      ...this.state,
      disabled: true,
    });
  }

  handleBack = e => {
    this.props.toggleModal(false, '');
    this.setState({
      ...this.state,
      disabled: true,
    })
  }

  handleConfirm = e => {
    const qtdFields = this.props.config.conversationRatingLabels.length;
    const conversationId = this.props.messages[1] ? this.props.messages[1].context.conversation_id : false;
    const score = document.querySelector('input:checked').value;

    const startMessage = new Date(first(this.props.messages).date);
    const endMessage = new Date(this.getLastMessageTime());

    const form = {
      conversation_id: conversationId,
      startMessage,
      endMessage,
    }

    this.props.modal.onConfirm(form, qtdFields, score);
    this.handleClose();
  }

  getLastMessageTime() {
    let date = '';

    const size = this.props.messages.length;

    for (let i = size - 1; i >= 0; i--) {
      const temp = this.props.messages[i].date;
      if (temp) {
        date = temp;
        break;
      }
    }

    return date;
  }

  setBg = color => e => {
    if (!color) {
      e.currentTarget.style.backgroundColor = 'transparent';
      e.currentTarget.style.color = 'black';
      return false;
    }
    const color2 = cloneDeep(color);

    let fontColor = color2.darken(20);
    fontColor = fontColor.toRgbString();
    let backColor = color2.setAlpha(0.13);
    backColor = color2.toRgbString();

    e.currentTarget.style.backgroundColor = backColor;
    e.currentTarget.style.color = fontColor;
  }

  getModal() {
    const colors = this.props.config.headerColor || "#0067ac";
    const header = tinycolor(colors);
    const backgroundColor = header.toHexString();
    const color = header.getLuminance() > 0.5 ? "#333333" : "#F1f1f1";

    return (
      <div className='Modal-container' onClick={this.handleClose}>
        <div className='box' onClick={e => e.stopPropagation()}>
          <p className='text' style={{ whiteSpace: "pre-line" }}>
            {this.props.config.leaveChatModalMessage}
          </p>
          <Rate inline onClick={this.handleClick} />
          <ButtonPrint config={this.props.config} />
          <div className='action-buttons'>
            <button title='Sair da janela de avaliação' className='cancel' onClick={this.handleClose}>
              Sair
            </button>
            <button
              title='Voltar para o chat'
              className='back'
              style={{ color: "black" }}
              onClick={this.handleBack}
              onMouseOver={this.setBg(cloneDeep(header))}
              onMouseOut={this.setBg("")}
            >
              Voltar para o Chat
            </button>
            <button
              title='Enviar avaliação'
              className='send'
              disabled={this.state.disabled}
              style={{ backgroundColor: backgroundColor, color: color }}
              onClick={this.handleConfirm}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.modal.status ? this.getModal() : false;
  }
}

const mapStateToProps = state => ({
  config: state.config,
  modal: state.app.modal,
  messages: state.messages,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: (toggle, message) => dispatch(toggleModal(toggle, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);