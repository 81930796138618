import { store } from '../App';

class ChatSessionHelper {
  static createChatSession() {
    localStorage.setItem('session-messages', '{}');
    localStorage.setItem('session-unread-messages', '[]');
    localStorage.setItem('session-config', '{}');
    localStorage.setItem('last-activity', Date.now());
  }

  static checkSession() {
    if (localStorage.getItem('session-messages')) return true;
    return false
  }
  
  static getSessionData() {
    const localConversation = JSON.parse(localStorage.getItem('session-messages'));
    const localConfig = JSON.parse(localStorage.getItem('session-config'));
    const isCustomerSendMessageToOperator = JSON.parse(localStorage.getItem('isCustomerSendMessageToOperator'));

    return { conversation: localConversation, config: localConfig, isCustomerSendMessageToOperator };
  }
  
  static updateMessages(messages, configs) {
    if( !store.getState().app.websocket ) return false;
    localStorage.setItem('session-messages', JSON.stringify(messages));
    localStorage.setItem('session-config', JSON.stringify(configs));
    //console.log('config:' + JSON.stringify(configs))
    //console.log('messages:' + JSON.stringify(messages))
  }
  
  static endSession() {
    localStorage.clear();
  }
}

export default ChatSessionHelper;
