import React, { Component } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import { StylesProvider } from "@material-ui/core/styles";
import "./iframe.scss";

import tinycolor from "tinycolor2";
import ReCAPTCHA from "react-google-recaptcha";
import UserInfo from "../../services/user-info";

import { initConversation } from "../../actions/app";
import clientWidgetConfig from "../../services/client-config";

class ChatIntro extends Component {
  userInfo = UserInfo.getInfo();
  state;

  constructor() {
    super();
    const userDataDom = document.getElementById("omni-chat-script");
    this.state = {
      verified: false,
      alreadyGettedToken: false,
      showSSOIframe: false,
      clientIframeUrl: null,
      code: userDataDom ? userDataDom.getAttribute("data-code") : null,
      token: null,
      login: "",
      password: "",
      userName: userDataDom ? userDataDom.getAttribute("data-username") : "Usuário",
      cpf: "",
      sysId: userDataDom ? userDataDom.getAttribute("data-userid") : null,
      userHash: userDataDom ? userDataDom.getAttribute("data-userhash") : null,
      environment: userDataDom ? userDataDom.getAttribute("data-env") : "dev",
      tenant: userDataDom ? userDataDom.getAttribute("data-tenant") : null,
      clientId: userDataDom ? userDataDom.getAttribute("data-client") : null,
    };
  }

  async getUserToken() {
    let clientToken = null;
    const clientCode =
      this.state.code === "undefined" ||
      this.state.code === "null" ||
      this.state.code === ""
        ? null
        : this.state.code;

    if (this.state.tenant === "yara" && !this.state.alreadyGettedToken) {
      clientToken = await clientWidgetConfig["yara"].getUserToken(
        window.location.href,
        clientCode,
        this.state.sysId,
        this.state.userHash,
        this.state.environment
      );
      this.setState({
        ...this.state,
        alreadyGettedToken: true,
        token: clientToken && clientToken.result ? clientToken.result : null,
        showSSOIframe: clientToken && clientToken.showSSOIframe ? true : false,
        clientIframeUrl:
          clientToken && clientToken.showSSOIframe ? clientToken.url : null,
      });
    }
  }

  handleInit(formUserData) {
    if (this.props.config.isCaptchaActive && !this.state.verified) {
      return false;
    }
    const userData = {
      user_id: formUserData ? formUserData.user_id : "",
      user_token: this.state.token
        ? this.state.token
        : formUserData
        ? formUserData.user_token
        : "",
      userName: this.state.userName ? this.state.userName : "",
      cpf: this.state.cpf,
      sysId: this.state.sysId,
    };

    localStorage.setItem("userName", userData.userName);
    localStorage.setItem("cpf", userData.cpf);
    localStorage.setItem("user_id", userData.user_id);
    localStorage.setItem("user_sysId", userData.sysId);
    localStorage.setItem("user_token", userData.user_token);
    this.props.initConversation(userData);
  }

  getButtons(buttonStyles) {
    /* @martini - 2020-11-13
    Não iremos realizar a chamada do token nesse momento para 
    não gerar solicitação de autenticação do usuário.  
    Ver task 43608 no DevOps projeto 0087 
    
    fn que gera o token
    this.getUserToken();
    */
    let isDisabled =
      this.props.config.isCaptchaActive &&
      !this.state.verified &&
      (!this.state.userName || this.state.userName === "");
    let buttonStyle = !isDisabled
      ? buttonStyles
      : { opacity: 0.5, cursor: "initial", ...buttonStyles };
    let startButton = (
      <button
        title='Iniciar Conversa'
        disabled={isDisabled}
        className="init-button"
        onClick={(e) => this.handleInit()}
        style={buttonStyle}
      >
        Iniciar conversa
      </button>
    );
    let logoImage = this.props.config.isDisplayedLogo ? (
      <img
        title={`Logo ${this.props.config.tenantName}`}
        src={this.props.config.welcomeImageUrl}
        className="logo"
        alt="Logo Cognitive Assistant"
      />
    ) : null;
    if (this.props.config.isWelcomeFormActive) {
      const correctLengthCPF = this.state.cpf.length >= 11;
      const validCPF = this.validateCPF(this.state.cpf);
      const validName = this.state.userName && this.state.userName.length > 0;
      const passedCaptcha =
        !this.props.config.isCaptchaActive || this.state.verified;
      isDisabled = !(
        correctLengthCPF &&
        passedCaptcha &&
        validCPF &&
        validName
      );
      buttonStyle = isDisabled
        ? { opacity: 0.5, cursor: "initial", ...buttonStyles }
        : buttonStyles;
      const customFields = (
        <div>
          <StylesProvider injectFirst={true}>
            <FormControl>
              <InputLabel htmlFor="userNameForm">Nome</InputLabel>
              <Input
                type="text"
                onChange={this.handleChangeUserName.bind(this)}
                id="userNameForm"
                placeholder="Digite seu nome aqui"
              />
              <FormHelperText id="userNameForm-helper-text"></FormHelperText>
            </FormControl>
            <FormControl error={correctLengthCPF && !validCPF}>
              <InputLabel htmlFor="cpf">CPF</InputLabel>
              <Input
                type="text"
                onChange={this.handleChangeCpf.bind(this)}
                id="cpf"
                placeholder="Digite seu CPF aqui"
              />
              <FormHelperText id="cpf-helper-text">
                {correctLengthCPF && !validCPF ? "CPF Inválido" : ""}
              </FormHelperText>
            </FormControl>
          </StylesProvider>
        </div>
      );
      startButton = (
        <button
          title='Iniciar Atendimento'
          disabled={isDisabled}
          className="init-button"
          onClick={(e) =>
            this.handleInit.bind(this)({
              userName: this.state.userName,
              cpf: this.state.cpf,
              user_token: this.state.token,
              user_id: this.state.sysId,
            })
          }
          style={buttonStyle}
        >
          Iniciar Atendimento
        </button>
      );
      logoImage = this.props.config.isDisplayedLogo ? (
        <img
          title={`Logo ${this.props.config.tenantName}`}
          src={this.props.config.welcomeImageUrl}
          className="logo"
          alt="Logo Cognitive Assistant"
        />
      ) : null;
      return (
        <div>
          {" "}
          {customFields}
          {startButton}
          {logoImage}{" "}
        </div>
      );
    }

    if (!this.props.config.isChannelActive) {
      return (
        <span className="chat-disabled-message">
          {this.props.config.disabledMessage}
        </span>
      );
    }

    if (this.props.config.isCaptchaActive) {
      return (
        <div>
          <div className="recaptcha-and-logo">
            {logoImage}
            {this.props.config.isCaptchaActive ? this.getCaptcha() : false}
          </div>
          {startButton}
        </div>
      );
    }

    return (
      <div>
        {startButton}
        {logoImage}
      </div>
    );
  }

  handleChangeCaptcha = (e) => {
    this.setState({
      ...this.state,
      verified: true,
    });
  };

  getHeaderColors = () => {
    const colors = this.props.config.headerColor || "#0067ac";
    const header = tinycolor(colors);
    return {
      backgroundColor: header.toHexString(),
      color: header.getLuminance() > 0.5 ? "#333333" : "#F1f1f1",
    };
  };

  getCaptcha() {
    if (!this.props.config.isCaptchaActive) return false;
    const captcha = React.createRef();
    return (
      <div className="captcha-resize">
        <ReCAPTCHA
          ref={captcha}
          size={this.userInfo.isMobile ? "compact" : "normal"}
          sitekey="6LdjR4YUAAAAAFhZq5RZADsefDzCydPy3mC36dxj"
          onChange={this.handleChangeCaptcha}
        />
      </div>
    );
  }
  validateLoginForm() {
    return this.state.login.length > 0 && this.state.password.length > 0;
  }

  handleChangeUserName(e) {
    this.setState({ userName: e.target.value });
  }

  handleChangeCpf(e) {
    e.target.FormHelperText = "error no CPF!!";
    e.target.value = e.target.value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");

    this.setState({ cpf: e.target.value.replace(/\D/g, "") });
  }

  validateCPF(strCPF) {
    let soma = 0;
    let resto = 0;
    let i = 0;

    for (i = 1; i <= 9; i++)
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(strCPF.substring(9, 10))) return false;

    soma = 0;
    for (i = 1; i <= 10; i++)
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }
  handleChangeLogin = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  handleChangePassword = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  parseWelcomeMessage(welcome, userName, assistantName) {
    let message = welcome;
    if (message.includes("#{user_name}")) {
      message = message.replace(
        "#{user_name}",
        userName
          ? userName
          : localStorage.getItem("userName")
          ? localStorage.getItem("userName")
          : "Colaborador"
      );
    }
    if (message.includes("#{botName}")) {
      message = message.replace(
        "#{botName}",
        assistantName ? assistantName : "bot"
      );
    }
    return message;
  }

  handleIframeRedirect(iframeId) {
    function iframeURLChange(iframe, callback) {
      const unloadHandler = () => {
        setTimeout(() => {
          if (
            !iframe ||
            !iframe.contentWindow ||
            !iframe.contentWindow.location
          ) {
            return;
          }
          if (iframe.contentWindow.location.href.includes("?code=")) {
            iframe.removeEventListener("load", attachUnload);
            iframe.contentWindow.removeEventListener("unload", unloadHandler);
          }
          callback(iframe.contentWindow.location.href);
        }, 0);
      };

      function attachUnload() {
        // Remove the unloadHandler in case it was already attached.
        // Otherwise, the change will be dispatched twice.
        iframe.contentWindow.removeEventListener("unload", unloadHandler);
        iframe.contentWindow.addEventListener("unload", unloadHandler);
      }
      iframe.addEventListener("load", attachUnload);
      attachUnload();
    }

    iframeURLChange(document.getElementById(iframeId), (newURL) => {
      if (newURL.includes("?code=")) {
        const url = newURL.split("?code=")[1];
        this.setState({
          ...this.state,
          showSSOIframe: false,
          code: url.includes("&") ? url.split("&")[0] : url,
        });
      }
    });
  }

  renderSSOIframe(iframeUrl) {
    const mainframe = (
      <iframe
        title="yara-client"
        className="iframe"
        id="clientIframe"
        src={iframeUrl}
      ></iframe>
    );
    if (this.state.alreadyGettedToken) {
      this.setState({
        ...this.state,
        alreadyGettedToken: false,
      });
    }
    return mainframe;
  }

  renderOmniSnippet(buttonStyles) {
    return (
      <React.Fragment>
        {this.props.config.botAvatarUrl ? (
          <img
            title={`Logo ${this.props.config.assistantName}`}
            src={this.props.config.botAvatarUrl}
            className="avatar"
            alt="Assistente Virtual"
          />
        ) : null}
        <div className="message" style={{whiteSpace: "pre-line"}}>
          {this.parseWelcomeMessage(
            this.props.config.welcomeText,
            this.state.userName,
            this.props.config.assistantName
          )}
        </div>
        {this.getButtons(buttonStyles)}
        <div
          hidden={!this.props.config.isCustomizedFooter}
          className="text-link-inner"
        >
          <span className="footer-text">{this.props.config.footerText}</span>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { app } = this.props;
    const buttonStyles = this.getHeaderColors();
    const chatIntroClassName = `chat-intro ${app.minimize ? "omni-hide" : ""}`;
    if (document.getElementById("clientIframe")) {
      this.handleIframeRedirect("clientIframe");
    }
    return (
      <div className={chatIntroClassName}>
        {this.state.showSSOIframe
          ? this.renderSSOIframe(this.state.clientIframeUrl)
          : this.renderOmniSnippet(buttonStyles)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.config,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  initConversation: (config) => dispatch(initConversation(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatIntro);
