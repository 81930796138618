import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { emitWebSocketData } from "../../services/websocket";
import { sendEvaluation, setConversation } from "../../actions/message";

import "./rate-iteration.scss";

class RateIteration extends Component {
  state = {
    evaluation: "",
  };

  constructor(props) {
    super(props);
    this.hasEvaluation = this.hasEvaluation.bind(this);
  }

  handleClick = (e) => {
    this.setState({ evaluation: e.currentTarget.value });
    // this.props.sendEvaluation(e.currentTarget.value, this.props.id);
    const { interactionMachineId } = JSON.parse(
      localStorage.getItem("session-config") || "{}"
    );
    emitWebSocketData('evaluate-iteration-message', {value: e.currentTarget.value, id: interactionMachineId });
    const newMessages = this.props.messages;
    newMessages[this.props.position].iterationRate = e.currentTarget.value;
    this.props.setConversation(newMessages);
  };

  getEvaluation() {
    let positiveStyle = {};
    let negativeStyle = {};

    if (this.state.evaluation === "-1")
      negativeStyle = { color: "rgba(244, 67, 54, 0.8)" };
    if (this.state.evaluation === "1")
      positiveStyle = { color: "rgba(77, 143, 1, 0.8)" };

    return (
      <div className="evaluate-container">
        <button
          title="Gostei"
          name="positive"
          value="1"
          className="up"
          onClick={this.handleClick}
          style={positiveStyle}
        >
          <FontAwesomeIcon icon={faThumbsUp} />
        </button>
        <button
          title="Não Gostei"
          name="negative"
          value="-1"
          className="down"
          onClick={this.handleClick}
          style={negativeStyle}
        >
          <FontAwesomeIcon icon={faThumbsDown} />
        </button>
      </div>
    );
  }

  getEvaluated() {
    if (
      !this.state.evaluation &&
      (!this.props.action || this.props.action === "validate")
    )
      return false;

    let style = {};

    if (this.state.evaluation === "-1" || this.props.action === "negative")
      style = { color: "rgba(244, 67, 54, 0.8)" };
    if (this.state.evaluation === "1" || this.props.action === "positive")
      style = { color: "rgba(77, 143, 1, 0.8)", transform: "rotateY(180deg)" };

    return (
      <div className="evaluated-container">
        <div style={style}>
          {this.state.evaluation === "1" || this.props.action === "1" ? (
            <FontAwesomeIcon icon={faThumbsUp} />
          ) : (
            <FontAwesomeIcon icon={faThumbsDown} />
          )}
        </div>
      </div>
    );
  }

  hasEvaluation() {
    if (!this.props.action) return false;
    if (this.props.position === 0) return false;
    if (this.props.action === "no-evaluate") return false;
    return this.props.config.isInteractionRatingActive;
  }

  render() {
    if (!this.hasEvaluation()) return false;
    return this.props.messages.length - 1 === this.props.position
      ? this.getEvaluation()
      : this.getEvaluated();
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) => ({
  sendEvaluation: (index, type) => dispatch(sendEvaluation(index, type)),
  setConversation: (conversation) => dispatch(setConversation(conversation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateIteration);
