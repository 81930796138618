import AppServices from "./appservices";

class UploadService extends AppServices {
  appendFiles(file, data) {
    const formData = new FormData();
    Object.entries(data.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", file);
    return formData;
  }

  upload(file, data) {
    const formData = this.appendFiles(file, data);
    return fetch(data.url, {
      method: "POST",
      body: formData,
    })
      .then((success) => {
        return success;
      })
      .catch((err) => {
        console.log("erro request s3 post --> ", err);
        return err;
      });
  }
}

export default UploadService;
