import React, { Component } from "react";
import { connect } from "react-redux";

import ChatHeader from "./chat-header";
import ChatIntro from "./chat-intro";
import ChatContent from "./chat-content";
import UserInfo from "../../services/user-info";

import "./chat.scss";

class Chat extends Component {
  userInfo = UserInfo.getInfo();

  renderContent() {
    return !this.props.app.conversation ? <ChatIntro /> : <ChatContent />;
  }

  render() {
    const isMobile = this.userInfo.isMobile;
    const { isRoundedBorderActive } = this.props.config;
    const chatContainerClassName = `chat-container ${
      this.props.app.minimize ? "omni-hide" : ""
    } ${isRoundedBorderActive ? "roundedBorder" : ""}`;
    const chatContainerClassNameMobile = `${
      this.props.app.minimize && isMobile
        ? "chat-container-eds-mobile"
        : "chat-container"
    } ${isRoundedBorderActive ? "roundedBorder" : ""}`;
    // const chatContainerClassName = `chat-container ${isRoundedBorderActive ? "roundedBorder" : ""}`;
    return (
      <div
        className={
          isMobile ? chatContainerClassNameMobile : chatContainerClassName
        }
      >
        <div>
          <ChatHeader />
          {/* deprecated for now */}
          {/* {this.props.config.isTextToSpeechActive ? (<ChatOptions /> ) : null } */}
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  config: state.config,
});

export default connect(mapStateToProps)(Chat);
