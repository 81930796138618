import React, { Component } from "react";
import { connect } from "react-redux";
import tinycolor from "tinycolor2";

import UserInfo from "../../services/user-info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp, faVolumeOff, faTimes, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import ChatUpload from './chat-upload';
import { sendMessage } from "../../actions/message";
import {
  toggleTtsActive,
  toggleMinimize,
  toggleChat,
  toggleOptions,
  closeConversation,
  toggleModal,
  sendEvaluation,
  toggleAttachLoad,
  loadedTTSSound,
  toggleMsgSound,
  toggleAttachAvailable,
  toggleAllowAttachment,
} from "../../actions/app";

class ChatHeader extends Component {

  buttonSize = "xs";
  userInfo = UserInfo.getInfo();
  constructor(props) {
    super(props);

    this.sendEvaluation = this.sendEvaluation.bind(this);
    this.getAttachButton = this.getAttachButton.bind(this);
  }

  handleClose = e => {
    e.stopPropagation();
    if (!this.props.app.conversation || !this.props.app.websocket || !this.props.config.isConversationRatingActive) {
      clearTimeout(this.props.app.counter);
      this.props.closeChat();
      if (this.props.app.minimize)
         this.handleToggleMinimize();
         
      return false;
    }

    const message = this.props.config.ratingRequestMessage === '' ? "Gostaria de avaliar sua experiência com o chat antes de sair?" : this.props.config.ratingRequestMessage;
    this.props.toggleModal(true, message, this.sendEvaluation, this.props.closeChat);
  }

  sendEvaluation(form, qtdFields, score) {
    this.props.sendEvaluation(form, qtdFields, score);
  }

  handleToggleMinimize = e => {
    this.props.toggleMinimize(!this.props.app.minimize);
  }

  toggleTTS = e => {
    e.stopPropagation();

    if (this.props.app.ttsActive && this.props.app.loadedTtsSound) {
      const { loadedTtsSound } = this.props.app
      loadedTtsSound.stop();
      this.props.loadedTTSSound(false);
    }

    this.props.toggleTtsActive(!this.props.app.ttsActive)
  }

  handleMsgSound = e => {
    e.stopPropagation();
    this.props.toggleMsgSound(!this.props.app.msgSoundActive)
  }


  getOptions(buttonStyles) {
    if (this.userInfo.browser === "Internet Explorer" && this.userInfo.version === "11.0") return false;
    if (!this.props.app.conversation || !this.props.config.isTextToSpeechActive) return null;
    const active = this.props.app.ttsActive ? "active" : "";
    const icon = this.props.app.ttsActive ? faVolumeUp : faVolumeOff;
    return (
      <button alt="Texto em fala" title="Texto em fala" className={active} onClick={this.toggleTTS} style={buttonStyles}>
        <FontAwesomeIcon icon={icon} size={this.buttonSize} />
      </button>
    );
  }

  getHeaderColors = () => {
    const colors = this.props.config.headerColor || "#0067ac";
    const header = tinycolor(colors);
    return {
      backgroundColor: header.toHexString(),
      color: header.getLuminance() > 0.5 ? "#333333" : "#F1f1f1"
    };
  }

  previewFile(file) {
    const reader = new FileReader();
    let preview;
    reader.readAsDataURL(file);

    reader.addEventListener("load", function () {
      preview = reader.result;
      sessionStorage.setItem('preview', preview)
    })
  }

  selectFile = e => {
    e.stopPropagation();
    return e.currentTarget.children['attachment'].click()
  }

  getAttachButton(buttonStyles) {
    return <ChatUpload buttonHeader={{buttonStyles}} app={this.props.app} config={this.props.config} message={this.props.message} ws={this.props.ws}></ChatUpload>
  }

  render() {
    const headerStyles = this.getHeaderColors();
    const buttonStyles = {
      color: headerStyles.color
    };
    let isMobile = this.userInfo.isMobile
    let close = `close-btn-eds ${this.props.app.minimize && isMobile ? "omni-hide" : ""}`;
    let volume = `volume-btn-eds ${this.props.app.minimize && isMobile ? "omni-hide" : ""}`;
    let titleChat = `text-title ${this.props.app.minimize && isMobile ? "omni-hide" : ""}`;
    let headerChat = `${this.props.app.minimize && isMobile ? "header-mobile" : "header"}`;
    
    return (
      <div id="omni-header-chat" className={headerChat} style={headerStyles}>
        <div
          title='Ligar ou Desligar o Alerta Sonoro de recebimento de mensagens'
          className={`${volume} title-icon`}
          onClick={e => this.handleMsgSound(e)}>
          <FontAwesomeIcon icon={this.props.app.msgSoundActive ? faVolumeUp : faVolumeOff} size="lg" />
        </div>
        <div className={titleChat}>{"Chat"}</div>
        <div className="actions">
          
          {this.props.app.websocket && this.props.app.attachAvailable ? this.getAttachButton(buttonStyles) : false}
          
          {this.getOptions(buttonStyles)}
          <button id="omni-max-chat" className="minimize-btn-eds" alt="Minimizar" title="Minimizar" onClick={e => this.handleToggleMinimize()} style={buttonStyles}>
            <FontAwesomeIcon icon={this.props.app.minimize ? faAngleUp : faAngleDown} size={this.buttonSize} />
          </button>
          {/* <button alt="Exandir" title="Expandir" onClick={} style={buttonStyles}>
            <FontAwesomeIcon icon={faArrowUp} size={this.buttonSize}faVolumeOff />
          </button> */}
          <button className={close} alt="Fechar" title="Fechar" onClick={this.handleClose} style={buttonStyles}>
            <FontAwesomeIcon icon={faTimes} size={this.buttonSize} />
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  messages: state.messages,
  app: state.app,
  config: state.config,
  conversation: state.conversation
});

const mapDispatchToProps = dispatch => ({
  toggleAttachAvailable: status => dispatch(toggleAttachAvailable(status)),
  toggleAllowAttachment: status => dispatch(toggleAllowAttachment(status)),
  toggleMinimize: toggle => dispatch(toggleMinimize(toggle)),
  toggleOptions: toggle => dispatch(toggleOptions(toggle)),
  toggleTtsActive: toggle => dispatch(toggleTtsActive(toggle)),
  toggleMsgSound: toggle => dispatch(toggleMsgSound(toggle)),
  loadedTTSSound: sound => dispatch(loadedTTSSound(sound)),
  toggleModal: (toggle, message, onConfirm, onClose) => dispatch(toggleModal(toggle, message, onConfirm, onClose)),
  sendEvaluation: (form, qtdFields, score) => dispatch(sendEvaluation(form, qtdFields, score)),
  sendMessage: (message, from) => dispatch(sendMessage(message, from)),
  toggleAttachLoad: status => dispatch(toggleAttachLoad(status)),
  closeChat: () => {
    dispatch(toggleChat(false))
    dispatch(closeConversation())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatHeader);
