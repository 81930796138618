import AppServices from "./appservices";

const appServices = new AppServices();
export default {
  'yara': {
    getUserToken: async (href, code, userId, userHash, env) => {
      let domain = null;
      if (href.includes('service-now')) {
        domain = href.split('.service-now')[0].split('://', 2)[1];
      }
      if (!domain || !userHash || !userId) { return; }
      const params = {
        code: code,
        domain: domain,
        client: 'yara',
        env,
        userId,
        userHash
      };
      const tokenParam = await appServices.request('/getOauth', 'POST', params);
      const result = await tokenParam.json();
      if (result && result.code === 'OK') {
        return {result: result.result};
      }
      if (result && result.code === 'INVALID_HASH') {
        return null;
      } else {
        if (result.code === 'NOT_HAS_CODE') {
          return {
            showSSOIframe: true,
            url: `https://${domain}.service-now.com/oauth_auth.do?response_type=code&redirect_uri=https://${domain}.service-now.com/coe&client_id=9879c4813b0b10100d73eea569cf3f52&state=code`
          }
          // window.location.assign(`https://${domain}.service-now.com/oauth_auth.do?response_type=code&redirect_uri=https://${domain}.service-now.com/sp&client_id=9879c4813b0b10100d73eea569cf3f52&state=code`);
        }
        return null;
      }
    }
  }
};
