import tinycolor from "tinycolor2";

export default class ColorHelper {
  
  constructor(color, options = {}) {
    if (!color) {
      throw new Error("Color not defined");
    }
    this.color = color;
    this.options = options;
  }

  setColor = color => {
    this.color = color;
    return tinycolor(this.color);
  }

  getColor = () => tinycolor(this.color);

  getAdaptativeColor = () => {
    const colorInstance = tinycolor(this.color);
    const type = colorInstance.isLight() ? "darken" : "brighten";
    return colorInstance[type](this.options[`${type}Amount`] || 75).toHexString();
  }

  getColorGuide = () => {
    const colorInstance = tinycolor(this.color);
    return {
      primaryColor: colorInstance.toHexString(),
      secondaryColor: this.getAdaptativeColor(),
      textColor: this.getAdaptativeColor()
    };
  };
}