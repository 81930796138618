import { combineReducers } from "redux";

import app from "./app";
import messages from "./messages";
import conversation from "./conversation";
import config from "./config";

export default combineReducers({
    messages,
    config,
    conversation,
    app
});