import React, { PureComponent } from "react";

import PDFIcon from "../../images/file-icons/pdf.svg";
import DOCIcon from "../../images/file-icons//doc.svg";
import MP3Icon from "../../images/file-icons/mp3.svg";
import TXTIcon from "../../images/file-icons/txt.svg";
import XLSIcon from "../../images/file-icons/xls.svg";
import ZIPIcon from "../../images/file-icons/zip.svg";
import AudioIcon from "../../images/file-icons/file-audio-regular.svg";
import VideoIcon from "../../images/file-icons/file-video-regular.svg";
import CSVIcon from "../../images/file-icons/file-csv-solid.svg";
import IMAGEIcon from "../../images/file-icons/file-image-regular.svg";

class Files extends PureComponent {
  fileTypes = [
    {
      type: ["jpeg", "jpg", "png", "gif", "svg"],
      image: IMAGEIcon,
    },
    {
      type: ["x-msvideo", "avi", "mpeg"],
      image: VideoIcon,
    },
    {
      type: ["csv"],
      image: CSVIcon,
    },
    {
      type: ["pdf"],
      image: PDFIcon,
    },
    {
      type: [
        "xls",
        "xlsx",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      image: XLSIcon,
    },
    {
      type: [
        "doc",
        "docx",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
      image: DOCIcon,
    },
    {
      type: ["plain"],
      image: TXTIcon,
    },
    {
      type: ["mp3"],
      image: MP3Icon,
    },
    {
      type: ["midi", "webm", "ogg", "wav", "aac"],
      image: AudioIcon,
    },
    {
      type: ["zip", "x-zip-compressed"],
      image: ZIPIcon,
    },
  ];

  constructor(props) {
    super(props);
    this.getFileThumb = this.getFileThumb.bind(this);
  }

  formatBytes = (bytes, decimals) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  getFileThumb(type) {
    const lastBar = type.lastIndexOf("/");
    const fileType = type.substring(lastBar + 1, type.length);

    for (let i = 0; i < this.fileTypes.length; i++) {
      const type = this.fileTypes[i].type.indexOf(fileType);
      if (type >= 0)
        return (
          <img
            className="file-image-thumbs"
            src={this.fileTypes[i].image}
            alt={`${fileType} icon`}
          />
        );
    }
  }

  render() {
    const { name, size, type, link } = this.props.file;
    const lastDot = name.lastIndexOf(".");
    const fileExtension = name.substring(lastDot, lastDot.length);
    const fileName = name.substring(0, lastDot);
    const fileType = type.search("image");

    return (
      <div className="files">
        <div className="file-image">
          {fileType > -1 ? (
            <img alt={fileName} src={link} width={"100%"} />
          ) : (
            this.getFileThumb(type)
          )}
        </div>
        <div className="bottom-container-file">
          <div className="file-name">
            <span className="name">{fileName}</span>
            <span className="extension">{fileExtension}</span>
          </div>
          <div className="file-size">{this.formatBytes(size)}</div>
        </div>
      </div>
    );
  }
}

export default Files;
