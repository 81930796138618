class ConversationHelper {

  static counter = 0;

  static parse(data, messages) { 

    this.setCounter(messages)
    const messageData = {};
    const actions = data.output ? data.output.options : {};
    messageData.message = data.message || "";

    if (data.firstMessage !== undefined) {
      messageData.context = data.context;
      messageData.message = data.output.text.join('');
    }

    if(data._id) {
      messageData.id = data._id;
    }
    
    if(data.time) {
      messageData.date = data.time
    }

    if (data.closed !== undefined) {
      messageData.message = data.text;
      messageData.closed = data.closed;
    }

    if (data.app !== undefined) {
      messageData.app = data.app;
    }

    if(actions){
      messageData.actions = actions;
      messageData.actions.position = this.counter;
    }

    if(data.rate) {
      messageData.actions = {
        rate: true
      }
    }

    if(data.interaction || data.closed !== undefined) {
      messageData.actions = {
        ...messageData.actions,
        interaction: true
      }
    }

    this.counter += 2;

    return messageData;
  }

  static setCounter(messages) {
    this.counter = 0;
    
    if(messages)
      this.counter = messages.length;
  }
}

export default ConversationHelper;
