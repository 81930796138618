import React, { Component } from "react";
import { connect } from "react-redux";
import { sendMessage } from "../../actions/message";
import { toggleAttachLoad } from "../../actions/app";
import { upload } from "../../actions/upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { emitWebSocketData } from "../../services/websocket";

class ChatUpload extends Component {
  state = { files: [] };
  formatFilesArray(ffs) {
    const files = [];
    for (let i = 0; i <= ffs.length - 1; i++) {
      const file = ffs[i];
      if (file) {
        files.push(file);
      } else {
        break;
      }
    }
    return files;
  }

  componentDidUpdate() {
    const actionMessage = JSON.parse(localStorage.getItem("action-context"));
    if (actionMessage) {
      const files = this.state.files;
      if (files && files.length > 0 && !this.state.alreadyUploadingFiles) {
        this.uploadFiles(actionMessage, files);
      }
    }
  }

  authorizedFiles(files, fileTypes) {
    for (const file of files) {
      let notAllowed = true;
      const lastBar = file.type.lastIndexOf("/");
      const fileType = file.type.substring(lastBar + 1, file.type.length);
      for (let i = 0; i < fileTypes.length; i++) {
        if (fileTypes[i].indexOf(fileType) !== -1) {
          notAllowed = false;
          break;
        }
      }
      if (notAllowed) {
        this.props.sendMessage(
          {
            message: this.props.config.attachmentNotSupportedMessage,
          },
          "system"
        );
        return false;
      }
      if (file.size > this.props.config.attachmentMaxSizeMB * 1024 * 1024) {
        this.props.sendMessage(
          {
            message: this.props.config.attachmentSizeLimitMessage,
          },
          "system"
        );
        return false;
      }
    }
    return true;
  }

  createPreSignedUrls(messageData, files) {
    messageData.context.s3Files = [];
    for (const file of files) {
      messageData.context.s3Files.push({
        fileSize: file.size,
        fileName: file.name,
        originalFileName: file.name,
        fileMimeType: file.type,
      });
    }

    messageData.actions = [{ name: "getAttachmentsUrls" }];
    emitWebSocketData("forwardToMicroAction", messageData);
  }

  async uploadFiles(messageData, files) {
    this.setState({
      ...this.state,
      alreadyUploadingFiles: true,
    });
    const newMessage = {
      context: { conversation_id: messageData.conversationId, s3Files: [] },
      actions: [{ name: "checkS3FileSize" }],
    };
    for (let i = 0; i <= files.length - 1; i++) {
      const file = files[i];
      const res = await upload(file, messageData.attachmentInfo[i].preSigned);
      if (res.statusText === "Bad Request") {
        console.debug("bad request on upload to S3 --> ", res);
        localStorage.setItem("action-context", null);
        this.props.sendMessage(
          {
            message:
              "Ops... Acho que esse anexo é inválido. Por favor insira outro arquivo.",
          },
          "system"
        );
        this.setState({
          ...this.state,
          files: [],
        });
        this.props.toggleAttachLoad(false);
        return;
      }
      if (!res.ok) {
        console.debug("upload to S3 response not ok --> ", res);
        localStorage.setItem("action-context", null);
        this.setState({
          ...this.state,
          files: [],
        });
        this.props.sendMessage(
          {
            message: this.props.config.loadingFileError
              ? this.props.config
                  .loadingFileError`- Arquivo: ${file.name.substring(
                  0,
                  file.name.lastIndexOf(".")
                )}.`
              : `Erro ao anexar o arquivo ${file.name.substring(
                  0,
                  file.name.lastIndexOf(".")
                )}. Por favor tente novamente.`,
          },
          "system"
        );
        this.props.toggleAttachLoad(false);
        return;
      }

      // preview só irá acontecer depois do upload do arquivo na AWS.
      //O espaço em 'message: " "' é para garantir a renderização com componente pelo recebimento de "nova mensagem";
      this.props.sendMessage(
        {
          message: " ",
          file: {
            name: file.name,
            type: file.type,
            size: file.size,
            link: `https://${messageData.attachmentInfo[i].preSigned.bucket}.s3.us-east-2.amazonaws.com/${messageData.attachmentInfo[i].preSigned.fileName}`,
            sent: false,
          },
        },
        "client"
      );

      newMessage.context.s3Files.push({
        originalFileName: file.name,
        fileMimeType: file.type,
        fileSize: file.size,
        fileName: messageData.attachmentInfo[i].preSigned.fileName,
        bucket: messageData.attachmentInfo[i].preSigned.bucket,
      });
    }
    this.fileInput.value = "";
    localStorage.setItem("action-context", null);
    this.setState({
      ...this.state,
      alreadyUploadingFiles: false,
      files: [],
    });
    emitWebSocketData("forwardToMicroAction", newMessage);
    this.props.toggleAttachLoad(false);
    return;
  }

  selectFile = (e) => {
    e.stopPropagation();
    return document.getElementById("attachment").click();
  };

  handleAttach = (e) => {
    e.stopPropagation();
    if (
      e.currentTarget.tagName === "BUTTON" ||
      e.currentTarget.files.length === 0
    )
      return false;
    const files = this.formatFilesArray(e.currentTarget.files);

    this.setState({
      ...this.state,
      files: files,
    });

    if (
      !this.authorizedFiles(files, this.props.config.attachmentAllowedTypes)
    ) {
      return;
    }
    this.props.toggleAttachLoad(true);
    this.props.sendMessage(
      { message: this.props.config.attachmentAwaitMessage },
      "system"
    );

    this.createPreSignedUrls(
      {
        context: {
          conversation_id: JSON.parse(localStorage.getItem("session-config"))
            .conversation_id,
        },
      },
      files
    );
  };

  render() {
    if (this.props.buttonHeader) {
      const attachmentButtonClass = !this.props.app.attachAvailable
        ? "blocked-attachment-button"
        : "attachment-button";
      return (
        <span
          style={this.props.buttonHeader.buttonStyles}
          className={attachmentButtonClass}
          onClick={this.selectFile}
        >
          <FontAwesomeIcon icon={faPaperclip} size={this.buttonSize} />
          <input
            disabled={!this.props.app.attachAvailable}
            id="attachment"
            onChange={this.handleAttach}
            style={{ display: "none" }}
            type="file"
            multiple
            ref={(ref) => (this.fileInput = ref)}
          />
        </span>
      );
    } else {
      return (
        <>
          <input
            id="attachment"
            style={{ display: "none" }}
            onChange={this.handleAttach}
            type="file"
            multiple
            ref={(ref) => (this.fileInput = ref)}
          />
          {!this.props.app.allowAttachment ? 
            (<button
              type="button"
              className="quick-replies"
              onClick={this.selectFile}
            >
              Anexar
            </button>) 
          : 
          false}
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  config: state.config,
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (conversation, from) =>
    dispatch(sendMessage(conversation, from)),
  toggleAttachLoad: (status) => dispatch(toggleAttachLoad(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatUpload);